import type { NamedDataDownloadUrl } from '@api/goose/dist/enhancedGooseClient';
import { generateEntity } from '@local/webviz/dist/context/snapshots/base';
import {
    DEFAULT_FLAT_COLOR,
    POINTS_DEFAULT_MODE,
    POINTS_DEFAULT_SIZE,
} from '@local/webviz/dist/context/snapshots/defaults';
import type { UpdateSnapshot } from '@local/webviz/dist/types/xyz';
import { ElementClass, ViewClass, toSuffixUid } from '@local/webviz/dist/xyz';

import type { PointSnapshotParams } from '../generateSnapshot.types';

export function generatePointsSnapshot(
    viewId: string,
    gooseObject: PointSnapshotParams,
): UpdateSnapshot | undefined {
    const { objectId, width, length, dataType, dataId, data } = gooseObject;

    const dataObject = data.find(
        (dataDownloadUrl) =>
            dataDownloadUrl.id === dataId ||
            (dataDownloadUrl as NamedDataDownloadUrl).name === dataId,
    );
    if (!dataObject?.download_url) {
        return undefined;
    }

    const elementId = toSuffixUid(objectId, ElementClass.Points);
    const snapshot = {
        [elementId]: generateEntity(ElementClass.Points, {
            verticesUrl: {
                location: dataObject.download_url,
                shape: [length, width],
                dataType,
            },
        }),
        [viewId]: generateEntity(ViewClass.Points, {
            id: viewId,
            element: elementId,
            color: DEFAULT_FLAT_COLOR,
            size: POINTS_DEFAULT_SIZE,
            mode: POINTS_DEFAULT_MODE,
        }),
    };
    return snapshot;
}
