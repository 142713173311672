import type { GetObjectByIdApiArg, GetObjectResponse } from '@api/goose/dist/enhancedGooseClient';
import { parseSuffixUid } from '@local/webviz/dist/utilities';
import { ViewClass, toSuffixUid } from '@local/webviz/dist/xyz';

import type { store } from 'src/store/store';
import type { TreeStructure } from 'src/store/visualization/visualizationSlice.types';
import { extractSchema } from 'src/utils/extractSchema';
import { Schemas } from 'src/visualization/constants';

import type {
    DownholeCollectionType,
    GeoscienceBlockmodelObject,
    GeoscienceGMMObject,
    GeoscienceGridObject,
    GeoscienceLineObject,
    GeoscienceMeshObject,
    Tileset,
} from '../../types';
import type { CreateViewSnapshot, PointSnapshotParams } from './generateSnapshot.types';
import { generateBlocksyncReferenceSnapshot } from './objectSnapshots/generateBlocksyncReferenceSnapshot';
import { generateDownholeCollectionLinesSnapshot } from './objectSnapshots/generateDownholeCollectionLinesSnapshot';
import { generateGMMSurfaceSnapshot } from './objectSnapshots/generateGMMSurfaceSnapshot';
import { generateLinesSnapshot } from './objectSnapshots/generateLinesSnapshot';
import { generatePointsSnapshot } from './objectSnapshots/generatePointsSnapshot';
import { generateSurfaceSnapshot } from './objectSnapshots/generateSurfaceSnapshot';
import { generateTensorSurfaceSnapshot } from './objectSnapshots/generateTensorSurfaceSnapshot';

export function getViewIdFromObjectId(objectId: string, fullSchema: string) {
    const extractedSchema = extractSchema(fullSchema);
    switch (extractedSchema) {
        case Schemas.PointsetSchema:
            return toSuffixUid(objectId, ViewClass.Points);
        case Schemas.DownholeIntervalsSchema:
            return toSuffixUid(objectId, ViewClass.Lines);
        case Schemas.GeologicalModelMeshesSchema:
        case Schemas.TriangleMeshSchema:
            return toSuffixUid(objectId, ViewClass.Surface);
        case Schemas.Regular2DGridSchema:
            return toSuffixUid(objectId, ViewClass.TensorSurface);
        case Schemas.BlockSyncReferenceSchema:
            return toSuffixUid(objectId, ViewClass.Blockmodel);
        default:
            return '';
    }
}

/**
 * getObjectIdFromViewId
 *
 * Since we construct our IDs in the format <hash>:<optional suffix(es)>:<ViewClass>, we can extract
 * the object ID by splitting the view ID by colons and removing the view class.
 *
 * Composite objects have the same hash, and are identified by suffixes that represent the name of
 * the object in the composite or path from the parent object to the child in the case of GMMs. The
 * suffixes differentiate between the different objects in the composite.
 *
 * @param viewId the ViewID to tokenize and extract the object ID from
 * @returns string: the object ID
 */
export function getObjectIdFromViewId(viewId: string) {
    return parseSuffixUid(viewId);
}

export interface CreateViewSnapshotProps {
    treeItem: TreeStructure;
    gooseResponse: GetObjectResponse;
    dispatch: typeof store.dispatch;
    params: GetObjectByIdApiArg;
    tileset?: Tileset;
}
export function createViewSnapshot({
    gooseResponse,
    treeItem,
    params,
    tileset,
    dispatch,
}: CreateViewSnapshotProps): CreateViewSnapshot {
    let viewId = '';
    const { object, object_id: objectId, links } = gooseResponse;

    const viewSnapshot = generateSnapshot();
    if (!viewSnapshot) {
        // eslint-disable-next-line no-console
        console.warn(`Unsupported schema: ${object.schema}`);
    }
    function generateSnapshot() {
        const schema = extractSchema(object.schema);
        switch (schema) {
            case Schemas.DownholeCollectionSchema: {
                if (treeItem.schema === Schemas.DownholeIntervalsSchema) {
                    if (!tileset) {
                        return undefined;
                    }
                    viewId = toSuffixUid(treeItem.treeId, ViewClass.Lines);
                    return generateDownholeCollectionLinesSnapshot(
                        treeItem,
                        tileset,
                        object as DownholeCollectionType,
                        dispatch,
                    );
                }
                viewId = toSuffixUid(treeItem.treeId, ViewClass.Points);
                const coordinates = object.location?.coordinates;
                if (!coordinates?.data) {
                    return undefined;
                }
                const snapshotParams: PointSnapshotParams = {
                    objectId,
                    width: coordinates.width,
                    length: coordinates.length,
                    dataType: coordinates.data_type,
                    dataId: coordinates.data,
                    data: links.data,
                };
                return generatePointsSnapshot(viewId, snapshotParams);
            }
            case Schemas.TriangleMeshSchema: {
                viewId = toSuffixUid(treeItem.treeId, ViewClass.Surface);

                return generateSurfaceSnapshot(
                    viewId,
                    gooseResponse as GeoscienceMeshObject,
                    params,
                    dispatch,
                );
            }
            case Schemas.DownholeIntervalsSchema: {
                viewId = toSuffixUid(treeItem.treeId, ViewClass.Lines);
                return generateLinesSnapshot(
                    viewId,
                    gooseResponse as GeoscienceLineObject,
                    params,
                    dispatch,
                );
            }
            case Schemas.PointsetSchema: {
                viewId = toSuffixUid(treeItem.treeId, ViewClass.Points);
                const coordinates = object.locations?.coordinates;
                if (!coordinates?.data) {
                    return undefined;
                }
                const snapshotParams: PointSnapshotParams = {
                    objectId,
                    width: coordinates.width,
                    length: coordinates.length,
                    dataType: coordinates.data_type,
                    dataId: coordinates.data,
                    data: links.data,
                };
                return generatePointsSnapshot(viewId, snapshotParams);
            }
            case Schemas.Regular2DGridSchema: {
                viewId = toSuffixUid(treeItem.treeId, ViewClass.TensorSurface);
                return generateTensorSurfaceSnapshot(
                    viewId,
                    gooseResponse as GeoscienceGridObject,
                    params,
                    dispatch,
                );
            }
            case Schemas.GeologicalModelMeshesSchema: {
                if (!tileset) {
                    return undefined;
                }
                viewId = toSuffixUid(treeItem.treeId, ViewClass.Surface);
                return generateGMMSurfaceSnapshot(
                    viewId,
                    gooseResponse as GeoscienceGMMObject,
                    treeItem,
                    tileset,
                );
            }
            case Schemas.BlockSyncReferenceSchema: {
                viewId = toSuffixUid(treeItem.treeId, ViewClass.Blockmodel);
                return generateBlocksyncReferenceSnapshot(
                    viewId,
                    gooseResponse as GeoscienceBlockmodelObject,
                    params,
                    dispatch,
                );
            }
            default:
                return undefined;
        }
    }

    return {
        viewId,
        snapshot: viewSnapshot,
    };
}
